<template>
  <div class="form-group c-filterMultiSelect">
    <label v-if="label" :for="id"
      >{{ label }}:

      <i
        :id="`icon-${_uid}`"
        v-if="showPopoverTitle || showPopoverContent"
        :class="tooltipIcon"
      />
    </label>

    <b-popover
      v-if="showPopoverTitle || showPopoverContent"
      :target="`icon-${_uid}`"
      :placement="'rightbottom'"
      triggers="hover click"
    >
      <template v-if="showPopoverTitle" #title>
        <slot name="popoverTitle" />
      </template>
      <slot v-if="showPopoverContent" name="popoverContent" />
    </b-popover>

    <div
      class="c-s-filterMultiSelect"
      :class="icon"
      :style="textarea ? 'height: calc(1.5em + 4rem + 2px);' : ''"
    >
      <div v-if="icon" class="icon" />
      <br />
      <input
        v-if="!textarea"
        :id="id"
        v-maska="currentMask.mask"
        :disabled="disabled"
        :label="fieldtext"
        :maxlength="maxLength"
        :name="name"
        :placeholder="placeholder"
        :type="type"
        :value="maskedValue"
        @blur="onBlur"
        @maska="onMask"
        v-on:keyup="onKeyUp"
      />
      <textarea
        v-if="textarea"
        :id="id"
        v-maska="currentMask.mask"
        :disabled="disabled"
        :label="fieldtext"
        :maxlength="maxLength"
        :name="name"
        :placeholder="placeholder"
        rows="4"
        :value="maskedValue"
        @blur="onBlur"
        @maska="onMask"
      />
    </div>
    <error
      v-if="error"
      class="mt-n1"
      :message="errorMsg"
      variant="no-border no-background"
    />
  </div>
</template>

<script>
import Error from '@/components/basics/error/CustomError.vue';
import { mask, maska, tokens } from 'maska';

export default {
  data() {
    return {
      maskTypes: [
        { id: 'Phone', mask: '(###) ###-####' },
        { id: 'AppointmentId', mask: '##########' },
        { id: 'Date', mask: '##/##/####' },
        { id: 'Zipcode', mask: '#####' },
        { id: 'PhoneExt', mask: '#####' },
        { id: 'ZipcodeExt', mask: '#####-####' },
        { id: 'Day', mask: '##' },
        { id: 'Personalssn', mask: '###-##-####' },
        { id: 'Price' },
        { id: 'Money' },
        { id: 'Coords', mask: '##.########' },
        { id: 'SSNPatronal', mask: '###-######' },
        { id: 'LicenseNumber', mask: '###-####' },
        { id: 'UAGMId', mask: '########' },
        {
          id: 'MacAddress',
          mask: {
            mask: 'FF:FF:FF:FF:FF:FF',
            tokens: { F: { pattern: /[0-9a-fA-F]/, uppercase: true } },
          },
        },
        { id: 'Custom', mask: this.mask },
      ],
      maskedValue: this.value,
    };
  },
  components: {
    Error,
  },
  directives: { maska },
  props: {
    id: {
      type: String,
      default: () => '',
      meta: {
        description: 'Id of the tag',
        example: 'ddlOffice',
      },
    },
    name: {
      type: String,
      default: () => '',
      meta: {
        description: 'Name of the tag',
        example: 'ddlOffice',
      },
    },
    textarea: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: null, // Taken from the locale dictionary
      required: false,
      meta: {
        description: 'Label text',
        example: 'Office',
      },
    },
    placeholder: {
      type: String,
      default: '',
      meta: {
        description: 'Texto de referencia para el CustomDropDown',
        example: 'Select',
      },
    },
    required: {
      type: String,
      default: function () {
        return '';
      },
      meta: {
        description: 'Required flag',
      },
    },
    error: {
      type: String,
      default: '',
      meta: {
        description: 'Error state',
      },
    },
    errorMsg: {
      type: String,
      default: () => '',
      meta: {
        description: 'Error Message',
        example: 'This field is required',
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      meta: {
        description: 'Disabled flag',
      },
    },
    options: {
      type: Array,
      default: () => [],
      meta: {
        description: 'Elements who can be chosen',
        example: '[4, 8, 15, 16, 23, 42]',
      },
    },
    value: {
      type: String,
      default: () => null,
    },
    fieldvalue: {
      type: String,
      default: () => null,
      meta: {
        description: 'Field that contains the value that will be chosen',
        example: 'id',
      },
    },
    fieldtext: {
      type: String,
      default: () => null,
      meta: {
        description: 'Field that contains the text to display',
        example: 'name',
      },
    },
    clearable: {
      type: Boolean,
      default: () => false,
      meta: {
        description: 'Makes the clear button visible',
        example: 'true',
      },
    },
    isMultiple: {
      type: Boolean,
      default: () => false,
      meta: {
        description:
          'If the field is gonna be able to select multiple options or not',
        example: 'true',
      },
    },
    selectAllEnabled: {
      type: Boolean,
      default: () => false,
      meta: {
        description:
          'Whether or not display a button to select all options at once',
      },
    },
    maxLength: {
      type: Number,
      default: () => 8000,
      meta: {
        description: 'Max length of the text',
      },
    },
    icon: {
      type: String,
      default: null,
      meta: {
        description:
          'Go down, to the css section and look for a list of available icons. Use any class identifier found there.',
      },
    },
    mask: {
      type: String,
      default: null,
      meta: {
        description: 'Máscara del texto',
        example: '###-####',
      },
    },
    maskType: {
      type: String,
      default: null,
      meta: {
        description: 'Máscaras del texto',
        example: 'Texto requerido',
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    tooltipTitle: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
    tooltipIcon: {
      type: String,
      default: 'fal fa-exclamation-circle',
    },
    addMask: {
      type: Boolean,
      default: false,
    },
    showPopoverTitle: {
      type: Boolean,
      default: () => false,
    },
    showPopoverContent: {
      type: Boolean,
      default: () => false,
    },
    allowedMaskCharacters: {
      type: String,
      default: '',
      meta: {
        description: 'A string of characters that are allowed in the mask',
        example: '9A*#.-/()',
      },
    },
  },
  computed: {
    currentMask() {
      if (!this.maskType) return { id: '', mask: '' };

      let mask = this.maskTypes.find((x) => x.id == this.maskType);
      return mask != undefined ? mask : { id: '', mask: '' };
    },
  },
  methods: {
    validateMaskFormat(mask) {
      if (!this.allowedMaskCharacters) return true;

      const regex = new RegExp(
        `^[${this.escapeRegex(this.allowedMaskCharacters)}]*$`,
      );

      return regex.test(mask);
    },
    onMask(event) {
      let value = event.target.value;

      if (!this.validateMaskFormat(value)) {
        alert('The mask contains invalid characters.');
        event.preventDefault();
        return;
      }

      if (this.currentMask.id == 'MacAddress' || this.addMask) {
        this.$emit('input', event.target.value);
      } else {
        this.$emit('input', event.target.dataset.maskRawValue);
      }
    },
    onBlur(event) {
      if (this.currentMask.mask == null || this.currentMask.mask == '')
        this.$emit('input', event.target.value);
    },
    onKeyUp(e) {
      if (e.keyCode === 13) {
        this.$emit('on-enter');
      }
    },
  },
  watch: {
    value(newValue) {
      // The mac address is used with the full mask
      if (
        this.currentMask.mask == null ||
        this.currentMask.mask == '' ||
        this.currentMask.id == 'MacAddress'
      ) {
        this.maskedValue = newValue;
      } else {
        this.maskedValue = mask(newValue || '', this.currentMask.mask, tokens);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.c-filterMultiSelect {
  i {
    color: #eb6d00 !important;
  }

  .c-s-filterMultiSelect {
    background-color: $color-white;
    border-radius: 4px;
    border: 1px solid $color-label-default;
    display: flex;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.375rem 0.75rem;
    align-items: center;
    position: relative;

    ::v-deep .vs__dropdown-option.vs__dropdown-option--selected {
      /*background-color: #ececec;*/
      /*color:black;*/
      /*cursor: default;*/
      display: none;
    }

    ::v-deep .vs__dropdown-toggle {
      border: none;
    }

    ::v-deep .v-select {
      flex: 100% 1 1;
    }

    &.clock {
      ::v-deep .vs__selected-options {
        padding-left: 40px;
      }

      .icon {
        display: flex;
        align-items: center;
        position: absolute;
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        /*ICONS IDENTIFIER*/
        &:after {
          content: '\f017';
          font-family: 'Font Awesome 5 Pro', sans-serif;
          font-size: 19px;
          color: $color-primary;
          margin-right: 13px;
        }
      }
    }

    .c-selectAllFacade {
      background-color: $color-white;
      border: 1px solid rgba(60, 60, 60, 0.26);
      border-radius: 4px;
      padding: 0 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 5px;

      i {
        color: rgba(60, 60, 60, 0.5);
        margin-left: 5px;
      }
    }
  }

  .c-selectAllButton {
    appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    margin-top: 10px;
    text-decoration: underline;
  }
}

.form-group label {
  font: normal normal normal 14px/21px Lato !important;
  color: #707070 !important;
}

input {
  width: 100%;
  height: 100%;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
textarea {
  width: 100%;
  height: 100%;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
</style>
