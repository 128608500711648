<template>
  <section>
    <div class="col-md-12 my-4">
      <h3>Perfil de Usuario</h3>
    </div>
    <div class="col-md-12">
      <validation-observer
        class="container-fluid px-0"
        ref="profileForm"
        novalidate
      >
        <div class="main-page-container">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <validation-provider class="col-md-6" tag="div">
                  <base-input
                    v-model="model.email"
                    :label="$t('email')"
                    :disabled="true"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  rules="required|max:50"
                  tag="div"
                >
                  <base-input
                    v-model="model.firstName"
                    :error="errors[0]"
                    :error-msg="$t('namerequired')"
                    :label="$t('name')"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  rules="required|max:50"
                  tag="div"
                >
                  <base-input
                    v-model="model.lastPaternalName"
                    :error="errors[0]"
                    :error-msg="$t('paternalLastNameRequired')"
                    :label="$t('paternalLastName')"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  rules="max:50"
                  tag="div"
                >
                  <base-input
                    v-model="model.lastMaternalName"
                    :error="errors[0]"
                    :error-msg="$t('fieldRequired')"
                    :label="$t('maternalLastName')"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  :rules="{ required: !!model.carrierId }"
                >
                  <base-input
                    v-model="model.phone"
                    :error="errors[0]"
                    :error-msg="$t('phoneRequired')"
                    :label="$t('cellPhone')"
                    mask-type="Phone"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  :rules="{ required: !!model.phone }"
                >
                  <filter-select
                    v-model="model.carrierId"
                    :error="errors[0]"
                    :error-msg="$t('mustSelectOne')"
                    fieldtext="text"
                    fieldvalue="value"
                    :label="$t('provider')"
                    :options="collections.phoneProviders"
                    :placeholder="$t('select')"
                  />
                </validation-provider>

                <div class="col-md-6">
                  <h5>Alertas</h5>
                  <ul class="list-group list-group-flush">
                    <li
                      v-for="(alert, i) in model.userLocationsAlerts"
                      class="list-group-item pl-0"
                      :key="i"
                    >
                      {{ alert }}
                    </li>
                    <li
                      v-if="
                        !model.userLocationsAlerts ||
                        !model.userLocationsAlerts.length
                      "
                      class="list-group-item pl-0"
                    >
                      {{ $t('noAlertsAvailable') }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5" v-if="showExtraInfo">
            <div class="col-md-12 px-0">
              <sub-header :title="$t('userParameters')" />
            </div>

            <validation-provider
              v-slot="{ errors }"
              class="col-md-6"
              rules="required"
            >
              <filter-select
                v-model="model.gender"
                :error="errors[0]"
                :error-msg="$t('mustSelectOne')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('genre')"
                :options="collections.genders"
                :placeholder="$t('select')"
                :clearable="false"
                translate
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-md-6"
              rules="required"
            >
              <base-datetime-picker
                format="dd/MMM/yyyy"
                :error="errors[0]"
                :error-msg="$t('fieldRequired')"
                :title="$t('birthDate')"
                type="date"
                :clearable="false"
                :value="model.birthDate"
                @change="model.birthDate = $event"
              />
            </validation-provider>
            <validation-provider class="col-md-6">
              <filter-select
                v-model="model.ethnicity"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('ethnicity')"
                :options="collections.ethnicities"
                :placeholder="$t('select')"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-md-6"
              rules="max:10"
            >
              <base-input
                v-model="model.zipCode"
                :error="errors[0]"
                :error-msg="$t('fieldRequired')"
                :label="$t('residenceZIPCode')"
              />
            </validation-provider>
          </div>
        </div>
      </validation-observer>
    </div>
    <div class="col-md-12 my-5 text-right">
      <button class="btn btn-primary ml-2" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
  </section>
</template>

<script>
import { modulesName } from '@/store';
import { mapState } from 'vuex';
import IdentityPermission from '@/constants/IdentityPermission';
import ProfileListType from '@/constants/ProfileListType';
import SubHeader from '@/components/SubHeader.vue';
import BaseInput from '@/components/BaseInput.vue';
import { getAllCarriers as _getAllCarriers } from '@/services/CarrierService';
import {
  getProfileLists as _getProfileLists,
  updateProfileBasicInfo as _updateProfileBasicInfo
} from '@/services/UserService';

export default {
  name: 'Profile',
  components: {
    SubHeader,
    BaseInput,
  },
  data() {
    return {
      model: {
        email: null,
        firstName: null,
        lastPaternalName: null,
        lastMaternalName: null,
        phone: null,
        carrierId: null,
        userLocationsAlerts: [],
        birthDate: null,
        zipCode: null,
        ethnicity: null,
        gender: null,
      },
      collections: {
        phoneProviders: [],
        genders: [],
        ethnicities: [],
      },
    };
  },
  computed: {
    ...mapState(modulesName.userModuleName, ['profile']),
    showExtraInfo() {
      return this.userHasPermissions(
        IdentityPermission.profile.basic.extra.edit,
      );
    },
  },
  methods: {
    async loadInitialFilters() {
      await _getAllCarriers()
        .then((response) => {
          this.collections.phoneProviders = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      await _getProfileLists(ProfileListType.Gender)
        .then((response) => {
          this.collections.genders = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      await _getProfileLists(ProfileListType.Ethnicity)
        .then((response) => {
          this.collections.ethnicities = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    initModel() {
      if (this.profile === null) return;

      this.model = {
        email: this.profile.email,
        firstName: this.profile.firstName,
        lastPaternalName: this.profile.lastPaternalName,
        lastMaternalName: this.profile.lastMaternalName,
        phone: this.profile.phone,
        carrierId: this.profile.carrierId,
        userLocationsAlerts:
          this.profile.userLocationsAlerts &&
          this.profile.userLocationsAlerts.length
            ? [...this.profile.userLocationsAlerts]
            : [],
        birthDate: this.profile.birthDate,
        zipCode: this.profile.zipCode,
        ethnicity: this.profile.ethnicity,
        gender: Number(this.profile.gender),
      };
    },
    async onSubmit() {
      if (!(await this.$refs.profileForm.validate())) return;

      await _updateProfileBasicInfo({...this.model})
      .then(() => {
        this.ShowSuccessSaveToast();
        this.$store.dispatch(`${modulesName.userModuleName}/setProfile`, {
            ...this.profile,
            ...this.model,
            fullName: `${this.model.firstName} ${this.model.lastPaternalName} ${this.model.lastMaternalName}`.trim()
        });
      })
    },
  },
  async mounted() {
    await this.loadInitialFilters();
    this.initModel();
  },
};
</script>
<style lang="scss" scoped>
</style>
