<template>
  <div
    class="error-container d-flex items-align-center"
    :class="customClasses"
  >
    <div class="w-100 text-left">
      <div
        v-if="message"
        class="d-inline"
      >
        <em
          v-if="isAlertMode"
          class="fa fa-bell-exclamation"
        />
        <em
          v-else
          class="fa fa-times-circle"
        />
      </div>
      <p
        class="text-left d-inline"
        v-html="message"
      />
      <slot name="action-text" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.error-container {
  padding: 10px 10px 0 10px;
  margin: 16px 0 0 0;
  position: relative;
}
.error-container .pl-0 {
  padding-left: 0;
}

.withoutspacing {
  margin-left: 0px;
  margin-right: 0px;
}

.error-container p {
  text-align: left;
  font: Lato, Regular;
  letter-spacing: 0;
  color: #ff5b5c;
  margin-bottom: 0px;
  font-weight: normal;
}

.error-container em {
  color: #ff5b5c;
  font-size: 18px;
  padding-right: 10px;
}

.error-container .alert p,
.error-container .alert em {
  color: #af6708;
}
.error-container .alert {
  background: #fff5e8;
  border: 1px solid #fdac41;
}
.error-container .no-border {
  border: none;
  background-color: transparent;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 5px;
}
.error-container .no-border p {
  font-size: 14px;
}
.error-container .no-border.pt-16 {
  padding-top: 16px;
}
.error-container .no-icon {
  padding-left: 0;
}
.error-container .no-icon em {
  display: none;
}
</style>

<script>
export default {
  name: 'Error',
  meta: {
    description: 'Componente personalizado para alertas de error.',
    slots: {
      default: {
        type: 'component',
        valid: [],
      },
    },
  },
  props: {
    message: {
      type: String,
      default: '',
      meta: {
        description: 'Mensaje mostrado en el interior del componente',
        example: 'Texto requerido',
      },
    },
    isAlertMode: {
      type: Boolean,
      default: false,
      meta: {
        description: 'Establece modo de alerta',
        example: 'true',
      },
    },
    variant: {
      type: String,
      default: '',
      meta: {
        description: 'Propiedad para definir estilos',
        example: 'Texto requerido',
      },
    },
  },
  computed: {
    customClasses() {
      return this.isAlertMode ? 'alert ' + this.variant : this.variant;
    },
  },
};
</script>